import * as React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Navigation from './Navigation'
import { Container } from '@mui/material'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { connect } from 'react-redux'
import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import config, { genders, divisions } from './config'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { getTieScores } from './Scores'
import { filterFixture, filterGroup, filterTeam } from './StateFilters'
import { sortBy } from 'underscore'

// const Itemi = styled(Box)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }))

// const TieContainer = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   color: theme.palette.text.secondary,
// }))

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

// const TButton = styled(Button)(({ theme }) => ({
//   color: theme.palette.text.secondary,
// }))

function StandingsPage({ auth, teams, selections, fixtures, dispatch, table, fixturesByTeams, groups, seasons }) {
  const [group, setGroup] = React.useState(selections.group || '')
  const [gender, setGender] = React.useState(selections.gender || config.genders[0].key)
  const [division, setDivision] = React.useState(selections.division || config.divisions[0].key)
  const [season, setSeason] = React.useState(selections.season || config.seasons[0].key)

  const handleGenderChange = (event) => {
    setGender(event.target.value)
  }

  const handleGroupChange = (event) => {
    setGroup(event.target.value)
  }

  const handleDivisionChange = (event) => {
    setDivision(event.target.value)
  }

  const handleSeasonChange = (event) => {
    setSeason(event.target.value)
  }

  React.useEffect(() => {
    if (division) {
      dispatch({
        type: 'UPDATE_DIVISION',
        payload: division,
      })
    }
    if (gender) {
      dispatch({
        type: 'UPDATE_GENDER',
        payload: gender,
      })
    }
    if (season) {
      dispatch({
        type: 'UPDATE_SEASON',
        payload: season,
      })
    }
    if (group) {
      dispatch({
        type: 'UPDATE_GROUP',
        payload: group,
      })
    } else {
      dispatch({
        type: 'CLEAR_GROUP',
      })
    }
  }, [gender, division, season, group, dispatch])

  // const renderTie = () => (
  //   <TieContainer sx={{ mb: 1 }}>
  //     <Grid container spacing={2}>
  //       <Grid item xs={12} sm={6} md={6}>
  //         <Typography variant='h6' component='div'>
  //           Ottelu
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={4} sm={2} md={2}>
  //         <Typography variant='body2' component='div'>
  //           12.5.2021 16:30
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={4} sm={2} md={2}>
  //         <Typography variant='body2' component='div'>
  //           Hiekkaharju
  //         </Typography>
  //       </Grid>
  //       <Grid item xs={4} sm={2} md={2}>
  //         <Typography variant='body2' component='div'>
  //           Kierros 1
  //         </Typography>
  //       </Grid>
  //     </Grid>
  //     <Grid item>
  //       <TableContainer component={Paper}>
  //         <Table sx={{ border: 0 }} size='small' aria-label='a dense table'>
  //           <TableBody>
  //             <TableRow sx={{ td: { border: 0 } }}>
  //               <TableCell sx={{ bgcolor: 'green' }}>Kenttä 1 - 16:30</TableCell>
  //               <TableCell align='right' sx={{ bgcolor: 'red' }}>
  //                 Jaako - Keijo
  //               </TableCell>
  //               <TableCell sx={{ bgcolor: 'green' }}>6-2, 6-5</TableCell>
  //             </TableRow>
  //             <TableRow sx={{ td: { border: 0 } }}>
  //               <TableCell>Kenttä 1 - 16:30</TableCell>
  //               <TableCell align='right'>Kaksinpeli</TableCell>
  //               <TableCell>6-2, 6-5</TableCell>
  //             </TableRow>
  //             <TableRow sx={{ td: { border: 0 } }}>
  //               <TableCell>Kenttä 1 - 16:30</TableCell>
  //               <TableCell align='right'>Nelinpeli</TableCell>
  //               <TableCell>6-2, 6-5</TableCell>
  //             </TableRow>
  //           </TableBody>
  //         </Table>
  //       </TableContainer>
  //     </Grid>
  //   </TieContainer>
  // )

  const renderSelectors = () => {
    // console.log(groups)
    return (
      <Item>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId='gender'
            id='gender-simple-select'
            value={gender}
            onChange={handleGenderChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {genders.map((g) => (
              <MenuItem value={g.key}>{g.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId='division'
            id='division-simple-select'
            value={division}
            onChange={handleDivisionChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {divisions.map((g) => (
              <MenuItem value={g.key}>{g.title}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {groups.length > 0 ? (
          !groups.includes(group) ? (
            setGroup(groups[0])
          ) : (
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <Select
                labelId='group'
                id='group-simple-select'
                value={group}
                onChange={handleGroupChange}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {groups.map((g) => (
                  <MenuItem value={g}>{g}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        ) : group !== null ? (
          setGroup(null)
        ) : null}
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId='season'
            id='season-simple-select'
            value={season}
            onChange={handleSeasonChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {seasons.map((g) => (
              <MenuItem key={g.key} value={g.key}>
                {g.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Item>
    )
  }
  return (
    <>
      <Box
        // sx={{
        //   display: 'flex',
        //   width: '100%',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        //   bgcolor: 'background.default',
        //   color: 'text.primary',
        //   borderRadius: 0,
        //   p: 0,
        // }}
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
        }}
      >
        <Container style={{ marginTop: 20 }}>
          <Navigation />
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={12} md={12}>
              {renderSelectors()}
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} spacing={2}>
              <TableContainer component={Paper}>
                <Table sx={{ border: 0 }} size='small' aria-label='a dense table'>
                  <TableHead>
                    <TableRow sx={{ td: { border: 0 } }}>
                      <TableCell>Joukkue</TableCell>
                      <TableCell align='center'>Ottelut</TableCell>
                      <TableCell align='center'>Voitot</TableCell>
                      <TableCell align='center'>Tasapelit</TableCell>
                      <TableCell align='center'>Häviöt</TableCell>
                      <TableCell align='center'>Pelien erotus</TableCell>
                      <TableCell align='center'>Pisteet</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table
                      ? table.map((t, index) => {
                          return (
                            <TableRow>
                              <TableCell>{t.name}</TableCell>
                              <TableCell align='center'>{t.ties}</TableCell>
                              <TableCell align='center'>{t.wins}</TableCell>
                              <TableCell align='center'>{t.draws}</TableCell>
                              <TableCell align='center'>{t.lost}</TableCell>
                              <TableCell align='center'>{t.gamediff}</TableCell>
                              <TableCell align='center'>{t.points}</TableCell>
                            </TableRow>
                          )
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

const sortTable = (table, fixtures) => {
  const sortedTable = Object.keys(table)
    .map((k) => table[k])
    .sort((a, b) => {
      if (a.points === b.points) {
        return a.gamediff > b.gamediff ? -1 : a.gamediff < b.gamediff ? 1 : 0
      } else {
        return a.points > b.points ? -1 : 1
      }
    })
    .map((x, i) => {
      x.sort = i
      return x
    })
  const rulesSorted = sortedTable.reduce((a, c) => {
    if (!a[c.points]) a[c.points] = []
    a[c.points].push(c)
    return a
  }, {})
  const _points = Object.keys(rulesSorted)
  for (let _p of _points) {
    if (rulesSorted[_p].length === 2) {
      // console.log('check these', rulesSorted[_p])
      let fixture = fixtures.find(
        (x) =>
          (x.value.home.id === rulesSorted[_p][0].id || x.value.away.id === rulesSorted[_p][0].id) &&
          (x.value.home.id === rulesSorted[_p][1].id || x.value.away.id === rulesSorted[_p][1].id)
      )
      // console.log('fixture', fixture)
      if (fixture) {
        const diffs = getTieScores(fixture.value.rubbers, fixture.value.sport)
        const homehigher = fixture.value.home.id === rulesSorted[_p][0].id
        // swap
        let homesort = rulesSorted[_p][0].sort
        let awaysort = rulesSorted[_p][1].sort

        if (!homehigher) {
          // swap
          homesort = rulesSorted[_p][1].sort
          awaysort = rulesSorted[_p][0].sort
        }
        // console.log('homesort', homesort, 'awaysort', awaysort)
        let swap = false
        if (homehigher && diffs.homepoints < diffs.awaypoints) {
          swap = true
        } else if (!homehigher && diffs.homepoints > diffs.awaypoints) {
          swap = true
        } else if (homehigher && diffs.homegames < diffs.awaygames) {
          swap = true
        } else if (!homehigher && diffs.homegames > diffs.awaygames) {
          swap = true
        }
        if (swap) {
          sortedTable[homesort].sort = awaysort
          sortedTable[awaysort].sort = homesort
        }
      }
    }
  }
  return sortBy(sortedTable, 'sort')
}

const countTable = (fixtures) => {
  const table = fixtures.reduce((acc, curr) => {
    if (curr.value.home.id === '' || curr.value.away.id === '') return acc
    if (!acc[curr.value.home.id])
      acc[curr.value.home.id] = {
        id: curr.value.home.id,
        name: curr.value.home.name,
        wins: 0,
        ties: 0,
        draws: 0,
        lost: 0,
        gamediff: 0,
        points: 0,
      }
    if (!acc[curr.value.away.id])
      acc[curr.value.away.id] = {
        id: curr.value.away.id,
        name: curr.value.away.name,
        wins: 0,
        ties: 0,
        draws: 0,
        lost: 0,
        gamediff: 0,
        points: 0,
      }
    if (curr.value.rubbers) {
      const diffs = getTieScores(curr.value.rubbers, curr.value.sport)
      if (diffs.homepoints > diffs.awaypoints) {
        acc[curr.value.home.id].wins++
        acc[curr.value.away.id].lost++
      } else if (diffs.homepoints < diffs.awaypoints) {
        acc[curr.value.away.id].wins++
        acc[curr.value.home.id].lost++
      } else if (diffs.homepoints > 0 && diffs.awaypoints > 0) {
        acc[curr.value.away.id].draws++
        acc[curr.value.home.id].draws++
      }
      acc[curr.value.home.id].points = acc[curr.value.home.id].points + diffs.homepoints
      acc[curr.value.away.id].points = acc[curr.value.away.id].points + diffs.awaypoints

      acc[curr.value.home.id].gamediff = acc[curr.value.home.id].gamediff + (diffs.homegames - diffs.awaygames)
      acc[curr.value.away.id].gamediff = acc[curr.value.away.id].gamediff + (diffs.awaygames - diffs.homegames)

      acc[curr.value.home.id].ties =
        acc[curr.value.home.id].wins + acc[curr.value.home.id].lost + acc[curr.value.home.id].draws
      acc[curr.value.away.id].ties =
        acc[curr.value.away.id].wins + acc[curr.value.away.id].lost + acc[curr.value.away.id].draws
    }
    return acc
  }, {})
  return table
}

const withTeams = compose(
  firebaseConnect(() => [
    {
      path: 'customers/lahiliiga/tournaments/teams',
    },
    {
      path: 'customers/lahiliiga/tournaments/fixtures',
    },
    {
      path: 'customers/lahiliiga/configs/seasons',
    },
  ]),
  connect((state, props) => {
    if (
      state.firebase.ordered.customers &&
      state.firebase.ordered.customers.lahiliiga &&
      state.firebase.ordered.customers.lahiliiga.tournaments &&
      state.firebase.ordered.customers.lahiliiga.tournaments.teams &&
      state.firebase.ordered.customers.lahiliiga.tournaments.fixtures &&
      state.sport.sport &&
      state.sport.gender &&
      state.sport.season &&
      state.sport.division
    ) {
      const _teams = state.firebase.ordered.customers.lahiliiga.tournaments.teams.filter((t) => {
        const ok = filterTeam(t, state)
        // if (ok) console.log(t, state)
        return ok
      })
      const _fixtures = state.firebase.ordered.customers.lahiliiga.tournaments.fixtures
        .filter((t) => filterFixture(t, state))
        // .filter((x) => !x.value.donotcount)
      // console.log('_teams', _teams, '_fixtures', _fixtures)
      const fixturesByTeams = _fixtures.reduce((acc, curr) => {
        // console.log(curr, acc)
        if (!acc[curr.value.home.id]) acc[curr.value.home.id] = []
        if (!acc[curr.value.away.id]) acc[curr.value.away.id] = []
        acc[curr.value.home.id].push(curr)
        acc[curr.value.away.id].push(curr)
        return acc
      }, {})

      const _groups = state.firebase.ordered.customers.lahiliiga.tournaments.teams
        .filter((t) => filterGroup(t, state))
        .map((t) => t.value.group)
        .filter((v, i, a) => a.indexOf(v) === i)
        .filter((x) => x)
        .sort()
      
      const table = countTable(_fixtures)
      const _table = sortTable(table, _fixtures)

      const seasons = state.firebase.ordered?.customers?.lahiliiga?.configs?.seasons ? state.firebase.ordered.customers.lahiliiga.configs.seasons.map((r) => {
        return { id: r.key, ...r.value }
      }) : []

      return {
        fixtures: _fixtures,
        teams: _teams,
        groups: _groups,
        auth: state.firebase.auth,
        selections: state.sport,
        dispatch: props.firebase.dispatch,
        table: _table,
        fixturesByTeams,
        seasons,
      }
    } else {
      return {
        fixtures: [],
        teams: [],
        groups: [],
        auth: state.firebase.auth,
        selections: state.sport,
        dispatch: props.firebase.dispatch,
        table: [],
        fixturesByTeams: {},
        seasons: [],
      }
    }
  })
)

export default withTeams(StandingsPage)
