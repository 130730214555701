import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Navigation from './Navigation'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { Container, FormControlLabel, Checkbox } from '@mui/material'
import { connect } from 'react-redux'
import { firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { genders, divisions } from './config'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { TeamColors } from '@cintoiacom/team-colors'
import PlayerAdd from './PlayerAdd'
import VerifiedIcon from '@mui/icons-material/Verified'

// const valuesToSchema = (type, title, array, cols) => {
//   const o = {
//     type,
//     title,
//     cols,
//     enum: [],
//     enumNames: [],
//   }
//   for (let i = 0; i < array.length; i++) {
//     o.enum.push(array[i].key)
//     o.enumNames.push(array[i].title)
//   }
//   return o
// }

const valuesToOptions = (array) => {
  return array.map((cell) => {
    return (
      <MenuItem key={cell.key} value={cell.key}>
        {cell.title}
      </MenuItem>
    )
  })
}

// const teamScheme = {
//   title: 'Joukkue',
//   description: 'Lisää/muokkaa joukkuetta',
//   type: 'object',
//   required: ['name', 'gender', 'division', 'season', 'contactname', 'contactemail', 'contactphone'],
//   properties: {
//     name: {
//       type: 'string',
//       title: 'Joukkueen nimi',
//       cols: 12,
//     },
//     contactname: {
//       type: 'string',
//       title: 'Yhteyshenkilö nimi',
//       cols: 4,
//     },
//     contactemail: {
//       type: 'string',
//       title: 'Yhteyshenkilö email',
//       cols: 4,
//     },
//     contactphone: {
//       type: 'string',
//       title: 'Yhteyshenkilö puhelin',
//       cols: 4,
//     },
//     sport: valuesToSchema('string', 'Laji', sports, 3),
//     season: valuesToSchema('string', 'Kausi', seasons, 3),
//     gender: valuesToSchema('string', 'Sukupuoli', genders, 3),
//     division: valuesToSchema('string', 'Divisioona', divisions, 3),
//     group: {
//       type: 'string',
//       title: 'Lohko',
//       cols: 3,
//     },
//   },
// }

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}))

const ThemeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
}))

const TeamForm = ({ league, formData, onFormikSubmit, seasons }) => {
  // console.log('league', league, seasons)
  const defaultValues = {
    name: '',
    contactname: '',
    contactemail: '',
    contactphone: '',
    group: '',
    season: '',
    gender: '',
    paid: false,
    logo: { color1: 1, color2: 4, color3: 3, shape: 4, template: 4 },
  }
  let initialValues = defaultValues
  if (formData?.id) {
    initialValues = { ...defaultValues, ...formData, leagueid: league.key }
  } else {
    initialValues.leagueid = league.key
  }
  // console.log('initialValues', initialValues)
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      onFormikSubmit(values)
    },
    enableReinitialize: true,
  })

  const randomLogoValues = () => {
    formik.setFieldValue('logo.template', Math.floor(Math.random() * 17))
    formik.setFieldValue('logo.shape', Math.floor(Math.random() * 5))
    formik.setFieldValue('logo.color1', Math.floor(Math.random() * 8))
    formik.setFieldValue('logo.color2', Math.floor(Math.random() * 8))
    formik.setFieldValue('logo.color3', Math.floor(Math.random() * 8))
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>
              <TextField
                fullWidth
                id='name'
                name='name'
                label='Joukkueen nimi'
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='template-label'>Kuvio</InputLabel>
                <Select
                  labelId='template-label'
                  id='team-select-template'
                  name='logo.template'
                  value={formik.values.logo.template}
                  label='Kuvio'
                  onChange={formik.handleChange}
                >
                  {[...Array(17).keys()].map((k) => (
                    <MenuItem value={k}>{k}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='shape-label'>Muoto</InputLabel>
                <Select
                  labelId='shape-label'
                  id='team-select-shape'
                  name='logo.shape'
                  value={formik.values.logo.shape}
                  label='Muoto'
                  onChange={formik.handleChange}
                >
                  {[...Array(5).keys()].map((k) => (
                    <MenuItem value={k}>{k}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button variant="outlined" onClick={() => randomLogoValues()}>Arvo Logo</Button>
            </Item>
          </Grid>

          <Grid item xs={2}>
            <Item>
              <TeamColors
                height={120}
                width={120}
                color1={formik.values.logo.color1}
                color2={formik.values.logo.color2}
                color3={formik.values.logo.color3}
                shape={formik.values.logo.shape}
                template={formik.values.logo.template}
              />
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='color1-label'>Väri 1</InputLabel>
                <Select
                  labelId='color1-label'
                  id='team-select-color1'
                  name='logo.color1'
                  value={formik.values.logo.color1}
                  label='Väri 1'
                  onChange={formik.handleChange}
                >
                  {[...Array(8).keys()].map((k) => (
                    <MenuItem value={k}>{k}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='color2-label'>Väri 2</InputLabel>
                <Select
                  labelId='color2-label'
                  id='team-select-color2'
                  name='logo.color2'
                  value={formik.values.logo.color2}
                  label='Väri 2'
                  onChange={formik.handleChange}
                >
                  {[...Array(8).keys()].map((k) => (
                    <MenuItem value={k}>{k}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                <InputLabel id='color3-label'>Väri 3</InputLabel>
                <Select
                  labelId='color3-label'
                  id='team-select-color3'
                  name='logo.color3'
                  value={formik.values.logo.color3}
                  label='Väri 3'
                  onChange={formik.handleChange}
                >
                  {[...Array(8).keys()].map((k) => (
                    <MenuItem value={k}>{k}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <TextField
                fullWidth
                id='contactname'
                name='contactname'
                label='Yhteyshenkilön nimi'
                value={formik.values.contactname}
                onChange={formik.handleChange}
                error={formik.touched.contactname && Boolean(formik.errors.contactname)}
                helperText={formik.touched.contactname && formik.errors.contactname}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <TextField
                fullWidth
                id='contactemail'
                name='contactemail'
                label='Yhteyshenkilön email'
                value={formik.values.contactemail}
                onChange={formik.handleChange}
                error={formik.touched.contactemail && Boolean(formik.errors.contactemail)}
                helperText={formik.touched.contactemail && formik.errors.contactemail}
              />
            </Item>
          </Grid>
          <Grid item xs={6}>
            <Item>
              <TextField
                fullWidth
                id='contactphone'
                name='contactphone'
                label='Yhteyshenkilö puhelin'
                value={formik.values.contactphone}
                onChange={formik.handleChange}
                error={formik.touched.contactphone && Boolean(formik.errors.contactphone)}
                helperText={formik.touched.contactphone && formik.errors.contactphone}
              />
            </Item>
          </Grid>
          {/* <Grid item xs={2}>
            <Item>
              <InputLabel id='league-label'>Sarja</InputLabel>
              <Select
                fullWidth
                labelId='league-label'
                id='team-select-league'
                value={formik.values.league}
                onChange={formik.handleChange}
                label='Sarja'
              >
                {valuesToOptions(leagues)}
              </Select>
            </Item>
          </Grid> */}
          <Grid item xs={2}>
            <Item>
              <InputLabel id='gender-label'>Sukupuoli</InputLabel>
              <Select
                fullWidth
                labelId='gender-label'
                id='gender'
                name='gender'
                value={formik.values.gender}
                onChange={formik.handleChange}
                label='Sukupuoli'
              >
                {valuesToOptions(genders)}
              </Select>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <InputLabel id='season-label'>Kausi</InputLabel>
              <Select
                fullWidth
                labelId='season-label'
                id='season'
                name='season'
                value={formik.values.season}
                onChange={formik.handleChange}
                label='Kausi'
              >
                {valuesToOptions(seasons)}
              </Select>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <InputLabel id='division-label'>Divisioona</InputLabel>
              <Select
                fullWidth
                labelId='division-label'
                id='division'
                name='division'
                value={formik.values.division}
                onChange={formik.handleChange}
                label='Divisioona'
              >
                {valuesToOptions(divisions)}
              </Select>
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <InputLabel id='group-labe'>Lohko</InputLabel>
              <TextField
                fullWidth
                id='group'
                name='group'
                labelId='group-label'
                value={formik.values.group}
                onChange={formik.handleChange}
                error={formik.touched.group && Boolean(formik.errors.group)}
                helperText={formik.touched.group && formik.errors.group}
              />
            </Item>
          </Grid>
          <Grid item xs={2}>
            <Item>
              <FormControlLabel control={<Checkbox checked={formik.values.paid} onChange={formik.handleChange} id='paid' name='paid' />} label="Maksanut" />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item>
              <ThemeButton variant='text' fullWidth type='submit' disabled={!(formik.values.division && formik.values.season && formik.values.gender && formik.values.name)}>
                Tallenna
              </ThemeButton>
            </Item>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

function TeamsPage({ auth, teams, push, update, set, teamsPerDivision, players, league, seasons }) {
  const [formData, setFormData] = useState(null)
  const [showEdit, setShowEdit] = useState(false)
  const [playerOpen, setPlayerOpen] = useState(false)

  const editTeam = (team) => {
    team.value.id = team.key
    setFormData(team.value)
    setShowEdit(true)
  }

  const renderDivision = (teams, title) => {
    return (
      <Item>
        <Typography variant='h6'>{title}</Typography>
        <Divider />
        <div>
          {teams.map((team) => {
            if (!team.value.logo) {
              team.value.logo = {
                color1: Math.floor(Math.random() * 8),
                color2: Math.floor(Math.random() * 8),
                color3: Math.floor(Math.random() * 8),
                shape: Math.floor(Math.random() * 5),
                template: Math.floor(Math.random() * 17),
              }
              update('customers/lahiliiga/tournaments/teams/' + team.key, { logo: team.value.logo })
            }
            let vals = []
            if (team.value.players) {
              vals = players.filter((o) => team.value.players.includes(o.id))
            }
            return (
              <Accordion>
                <AccordionSummary
                  style={{ display: 'flex' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={team.key + '-content'}
                  id={team.key + '-header'}
                >
                  <Typography variant='body2'>
                    <ModeEditIcon onClick={() => editTeam(team)} />{' '}
                    <TeamColors
                      width={15}
                      height={15}
                      color1={team.value?.logo?.color1 || 0}
                      color2={team.value?.logo?.color2 || 0}
                      color3={team.value?.logo?.color3 || 0}
                      template={team.value?.logo?.template || 0}
                      shape={1}
                    />{' '}
                    {team.value.name} |{' '}
                    <Typography
                      color={team.value.players && team.value.players.length > 1 ? 'lightgreen' : 'red'}
                      component={'span'}
                    >
                      {team.value.players ? team.value.players.length : '0'}
                    </Typography>
                    {team.value.paid ? (<>{' | '} <VerifiedIcon fontSize='small' color={'success'} /></>) : null}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Autocomplete
                    onChange={(event, array) => onUpdatePlayers(event, array, team.key)}
                    multiple
                    id={team.key}
                    options={players}
                    // isOptionEqualToValue={(option, value) => option.id === value.id }
                    getOptionLabel={(option) => [option.firstname, option.lastname].join(' ')}
                    // defaultValue={}
                    value={vals}
                    renderInput={(params) => <TextField {...params} variant='standard' label='Pelaajat' />}
                  />
                </AccordionDetails>
              </Accordion>
            )
          })}
        </div>
      </Item>
    )
  }

  const onUpdatePlayers = (event, values, teamid) => {
    const players = values.map((v) => v.id)
    update('customers/lahiliiga/tournaments/teams/' + teamid, { players })
  }

  const onFormikSubmit = (data) => {
    if (!data.group) delete data.group
    if (data.group && data.group.trim() === '') data.group = null
    if (data.id) {
      // console.log('customers/lahiliiga/tournaments/teams/' + data.id, data)
      set('customers/lahiliiga/tournaments/teams/' + data.id, data)
    } else {
      // console.log('customers/lahiliiga/tournaments/teams', data)
      push('customers/lahiliiga/tournaments/teams', data)
    }
    setFormData({})
    setShowEdit(false)
  }

  const isLoggedIn = !isEmpty(auth)
  const teamsLoaded = isLoaded(teams)
  if (!teamsLoaded) {
    return <></>
  }

  const closePlayer = () => {
    setPlayerOpen(false)
  }

  return (
    <>
      <Box
        // sx={{
        //   display: 'flex',
        //   width: '100%',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        //   bgcolor: 'background.default',
        //   color: 'text.primary',
        //   borderRadius: 0,
        //   p: 0,
        // }}
        sx={{
          width: '100vw',
          minHeight: '100vh',
          height: '100%',
          bgcolor: 'background.default',
          pb: 10,
        }}
      >
        <Navigation />
        <Container style={{ marginTop: 20 }}>
          <Grid container sx={{ mb: 2 }}>
            <Grid item xs={12}>
              {showEdit ? (
                <Item>
                  <TeamForm league={league} formData={formData} onFormikSubmit={onFormikSubmit} seasons={seasons} />
                </Item>
              ) : isLoggedIn ? (
                <Item>
                  <ThemeButton onClick={() => setShowEdit(true)} variant='text'>
                    Lisää joukkue
                  </ThemeButton>
                  <ThemeButton onClick={() => setPlayerOpen(true)} variant='text' sx={{ ml: 10 }}>
                    Lisää pelaaja
                  </ThemeButton>
                </Item>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {teamsPerDivision
              ? Object.keys(teamsPerDivision)
                  .sort()
                  .map((key) => {
                    return (
                      <Grid item xs={12} sm={6} md={3}>
                        {renderDivision(teamsPerDivision[key].teams, teamsPerDivision[key].title)}
                      </Grid>
                    )
                  })
              : null}
          </Grid>
        </Container>
      </Box>
      <PlayerAdd onClose={closePlayer} open={playerOpen} />
    </>
  )
}

const withTeams = compose(
  firebaseConnect(() => [
    {
      path: 'customers/lahiliiga/tournaments/teams',
    },
    {
      path: 'customers/lahiliiga/players',
    },
    {
      path: 'customers/lahiliiga/configs/seasons',
    },
  ]),
  connect((state, props) => {
    // console.log(state.sport)
    if (
      state.firebase.ordered.customers &&
      state.firebase.ordered.customers.lahiliiga &&
      state.firebase.ordered.customers.lahiliiga.tournaments &&
      state.firebase.ordered.customers.lahiliiga.players &&
      state.sport.league
    ) {
      const teamsPerDivision = state.firebase.ordered.customers.lahiliiga.tournaments.teams.reduce((acc, curr) => {
        if (curr.value.leagueid) {
          if (curr.value.leagueid !== state.sport.league.key) return acc
        } else if (curr.value.sport) {
          if (curr.value.sport !== state.sport.sport.toLowerCase() || curr.value.season !== state.sport.season) return acc
        }
        const division = divisions.find((x) => x.key === curr.value.division)
        const gender = genders.find((x) => x.key === curr.value.gender)
        let group = ''
        if (curr.value.group) {
          group = curr.value.group
        }
        const key = [curr.value.gender, curr.value.division, group].join('-')
        // console.log('gender', gender, 'division', division, 'curr', curr)
        const title = gender.title + ' - ' + division.title + (group ? ' - ' + group : '')
        // if (!acc[curr.value.sport]) acc[curr.value.sport] = {}
        // if (!acc[curr.value.sport][key]) acc[curr.value.sport][key] = []
        // acc[curr.value.sport][key].push(curr)
        if (!acc[key]) acc[key] = { title, teams: [] }
        acc[key].teams.push(curr)
        return acc
      }, {})

      const seasons = state.firebase.ordered?.customers?.lahiliiga?.configs?.seasons ? state.firebase.ordered.customers.lahiliiga.configs.seasons.map((r) => {
        return { id: r.key, ...r.value, title: r.value.name }
      }) : []

      return {
        players: state.firebase.ordered.customers.lahiliiga.players.map((p) => {
          return { id: p.key, ...p.value }
        }),
        teams: state.firebase.ordered.customers.lahiliiga.tournaments.teams,
        teamsPerDivision,
        auth: state.firebase.auth,
        league: state.sport.league,
        update: props.firebase.update,
        push: props.firebase.push,
        set: props.firebase.set,
        seasons
      }
    } else {
      return {
        players: [],
        teams: [],
        league: null,
        teamsPerDivision: null,
        auth: state.firebase.auth,
        update: props.firebase.update,
        push: props.firebase.push,
        set: props.firebase.set,
        seasons: []
      }
    }
  })
)

export default withTeams(TeamsPage)
